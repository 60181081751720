@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
img {
  max-width: 100%;
}
.herosection__container {
  /* min-height: 100vh; */
  width: 100%;
  background-image: url("../assets/images/img1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
}

.herosection {
  padding: 30px;
  row-gap: 10px;
  margin-top: 52px;
}

.herosection__text-block {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  gap: 10px;
}

.herosection__text {
  font-family: "Manrope", sans-serif;
  color: #fff;
  font-size: 64px;
  font-weight: 600;
  text-align: left;
  letter-spacing: -1.2px;
  line-height: 56px;
}

.herosection__text-block {
  width: 55%;
}

.herosection__image-text {
  width: 100%;
  max-width: 492px;
  padding-top: 5px;
}

.herosection__image {
  /* margin-top: -20px; */
  width: 45%;
  /* margin-left: -15px; */
}

.herosection__image img {
  width: 100%;
  /* height: 100px; */
  object-fit: contain;
}

.herosection__sub-text {
  font-family: "Manrope", sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 29px;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 10px;
  max-width: 786px;
  line-height: 33px;
}

.herosection__btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}

.hero__btn {
  height: 30px;
}

.hero__enroll-btn {
  background-color: #ffe52e;
  color: #000;
  border: none;
  padding: 20px;
  border-radius: 6px;
  text-decoration: none;
  height: 56px;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "inter-ebold";
  font-size: 16px;
  background-image: url("../assets/images/lineWhite.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-family: "Manrope", sans-serif;
  font-size: 25px;
  font-weight: 600;
}

.hero__pay-btn {
  background: transparent;
  color: #fff;
  border: none;
  border: 1px solid #ffe52e;
  padding: 20px;
  border-radius: 6px;
  text-decoration: none;
  height: 56px;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Manrope", sans-serif;
  font-size: 25px;
  font-weight: 600;
}

.herosection__download {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.herosection__text--yellow {
  color: #ffe52e;
  font-size: 14px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  background: linear-gradient(180deg, #ffe52e 21.22%, #c2aa00 85.36%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  padding-right: 9px;
}

.herosection__download--icon {
  padding: 10px;
  width: 56px;
  height: 56px;
  background: linear-gradient(180deg, #ffe52e 21.22%, #c2aa00 85.36%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.herosection__download--icon img {
  width: 24px;
}

@media (max-width: 576px) {
  .herosection__image-text {
    width: 250px;
    margin-bottom: 30px;
  }

  .herosection__text-block {
    /* align-self: center; */
    /* align-items: center; */
  }

  .herosection__image {
    width: 300px;
  }
}

@media (min-width: 767px) {
  .herosection__btns {
    flex-direction: row;
  }

  .herosection__text-block {
    align-items: flex-start;
  }

  .herosection__text {
    font-size: 55px;
  }

  .herosection__download {
    flex-direction: row;
    margin-top: 0px;
  }

  .herosection__image {
    width: 790px;
    margin-top: 50px;
  }

  .herosection__image-text {
    /* width: 100%; */
    /* height: 100px; */
    /* width: 350px; */
  }

  .herosection__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
  }

  .hero__enroll-btn {
    background-image: none;
    background: linear-gradient(180deg, #ffe52e 21.22%, #c2aa00 85.36%);
  }

  .herosection__text--yellow {
    font-size: 25px;
  }
}

@media (min-width: 1023px) {
  .herosection {
    padding: 20px 15px 50px;
    width: 1275px;
  }

  .herosection__top {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* gap: 30px; */
    justify-content: space-between;
  }

  .herosection__text-block {
    /* background-color: green; */
    /* margin-right: -80px; */
    /* padding-top: 58px; */
    letter-spacing: -1.6px;
    line-height: 56px;
    margin-top: -80px;
  }

  .herosection__image img {
    /* background-color: red; */
    /* margin-left: -60px; */
    /* padding-top: 120px; */
  }

  .herosection__sub-text {
    font-size: 26px;
    margin-top: -80px;
  }

  .herosection__download {
    /* margin-right: 50px; */
    margin-right: 22px;
  }
}

@media (max-width: 1279px) {
  .herosection {
    padding: 20px 50px 50px;
  }
  .herosection__text {
    font-size: 36px;
    line-height: 35px;
  }
}

@media (max-width: 1024px) {
  .herosection__container {
    min-height: inherit;
  }
  .herosection__text {
    font-size: 36px;
  }
  .herosection__sub-text {
    font-size: 24px;
  }
  .mentor__image-container {
    gap: 28px;
  }
  .mentors-from-images .mentor--image {
    height: 50px;
  }
  .mentors-from-images .mentor--image {
    height: 42px;
  }
}
@media (max-width: 1022px) {
  .herosection__container {
    min-height: inherit;
  }
  .herosection {
    padding: 25px 30px;
    margin-bottom: 20px;
  }
  .herosection__text-block {
    width: 100%;
  }
  .herosection__text {
    font-size: 32px;
    line-height: 32px;
  }
  .herosection__image {
    margin-top: 30px;
    width: 100%;
  }
  .herosection__sub-text {
    margin-left: 0;
    font-size: 17px;
    line-height: 20px;
  }
  .herosection__btns {
    gap: 20px;
  }
  .hero__enroll-btn {
    font-size: 16px;
    width: 175px;
    height: 37px;
  }
  .hero__pay-btn {
    font-size: 16px;
    width: 175px;
    height: 37px;
  }
  .herosection__text--yellow {
    font-size: 16px;
    padding: 0;
  }
  .herosection__download--icon {
    width: 31px;
    height: 31px;
    padding: 0;
  }
  .herosection__download--icon img {
    width: 15px;
  }
}
@media (max-width: 766px) {
  .herosection {
    padding: 25px 25px;
    margin-top: 75px;
  }
  .herosection__container {
    width: 100%;
    background-image: url("../assets/images/mobile-main-top-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .herosection__download {
    margin-top: 35px;
  }
  .mentor--image-block .mentor__image-container {
    margin-top: 50px;
  }
}
@media (max-width: 414px) {
  .herosection__sub-text {
    margin-top: 0;
  }
  .herosection__image {
    width: 70%;
    margin: 0 auto;
  }
  .herosection__text {
    font-size: 25px;
    line-height: 20px;
  }
  .herosection__image-text {
    width: 200px;
    margin-bottom: 20px;
  }
  .herosection__download {
    margin-top: 15px;
  }
  .herosection {
    margin-top: 65px;
  }
}
@media (max-width: 374px) {
  .herosection__text {
    font-size: 30px;
  }
}
