img {
  max-width: 100%;
}
.mentor__container {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mentor-block {
  padding: 10px;
  background-image: url(../assets/images/mentor-block-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}

.mentor {
  width: 100%;
}

.mentor__title--text {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 28px;
  letter-spacing: -1.94px;
  text-align: center;
  margin: 0px;
}
.mentor__title-bar .lineYellow-img {
  width: 100%;
}

.mentor--image-block {
  /* margin-bottom: 20px; */
  background-image: linear-gradient(#f5f5f5 0em, transparent 0.1em),
    linear-gradient(270deg, #f5f5f5 0em, transparent 0.1em);
  background-size: 1.5em 1.5em;
  /* transform: rotate(-10deg); */
  z-index: -100;
  padding: 30px 0;
  /* background-size: contain; */
  /* background-repeat: no-repeat; */
  background-position: center;
}

.ment {
  /* background-image: linear-gradient(#f5f5f5 .1em, transparent .1em), linear-gradient(90deg, #f5f5f5 .1em, transparent .1em);
    background-size: 1.5em 1.5em;
    transform: rotate(-10deg);
    z-index: -100;
    padding: 30px; */
  /* transform: rotate(10deg); */
}

.mentor--image {
  /* width: 30%; */
  /* height: 40px; */
  align-items: center;
  justify-content: center;
}

.mentor__card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.mentor__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 6px;
  border-radius: 10px;
  z-index: 1;
}

.mentor__card-eclps1 {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 0px;
  left: 0px;
  background: #f9d411;
  filter: blur(35px);
  overflow: hidden;
  z-index: -1;
}

.mentor__card-eclps2 {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 40px;
  bottom: 0px;
  overflow: hidden;
  background: #fe8616;
  filter: blur(35px);
  z-index: -1;
}

.mentor__card--name {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 800;
  color: #242121;
}

.mentor__card--desc {
  font-size: 16.23px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
  color: #242121;
}

.mentor__card--image {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  z-index: 5;
  background-color: #fff;
}

.mentor__card--content {
  width: 70%;
  /* background-image: url("../assets/images/mentorMesh.svg");
  background-size: cover;
  background-repeat: repeat;
  background-position: center; */
}

.meet-your-title {
  font-family: "Manrope", sans-serif;
  font-size: 50px;
  font-weight: normal;
  color: #242121;
  letter-spacing: -0.58px;
}
.mentors-text span {
  font-family: "Manrope", sans-serif;
  font-size: 50px;
  font-weight: 800;
  color: #242121;
  letter-spacing: -0.58px;
}
.mentors-text span img {
  padding-left: 5px;
}

.mentors-from-images {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  justify-content: center;
}
.mentors-from-images .mentor--image-item {
  padding: 0 20px;
}
.mentor__card-container.less__card-container {
  padding-top: 0;
  margin-top: -10px;
}
.mentor .show-more-less-clickable {
  background: #fff;
  position: relative;
  width: 100%;
  text-align: center;
  display: inline-block;
  height: 50px;
  margin-top: -18px;
}
.mentor .mentor-show-more {
  background: #fff;
  border: solid 2px #ffe52e;
  display: inline-block;
  padding: 5px 10px;
  margin: 0 auto;
  cursor: pointer;
}
.mentor-show-more.Less-more {
  margin-top: 25px;
}

@media (max-width: 1199px) {
  .mentor--image {
    height: 65px;
  }
}
@media (max-width: 1022px) {
  .mentor__card-container {
    flex-wrap: wrap;
  }
  .mentor-block {
    width: 100% !important;
  }
  .mentor__card {
    justify-self: start;
  }
  .mentor__image-container {
    gap: 28px;
  }
}
@media (max-width: 766px) {
  .mentor__title--text {
    font-size: 31px;
  }
  .mentor__container {
    padding: 30px 20px;
  }
  .mentors-from-images {
    flex-wrap: nowrap;
    margin-bottom: 20px !important;
  }
  .mentors-from-images .mentor--image-item {
    padding: 0 10px;
  }
  .mentor--image-block {
    padding-top: 2px;
    padding-bottom: 0;
  }
  .meet-title-block {
    text-align: center;
  }
  .meet-your-title {
    font-size: 31px;
  }
  .mentors-text span {
    font-size: 46px;
  }
  .mentors-text span img {
    height: 40px !important;
  }
  .mentor__card--image {
    width: 134px;
    height: 134px;
  }
  .mentor-block {
    display: inline-block;
    width: 100%;
  }
  .mentor__card {
    justify-content: start;
  }
  .mentor__card--name {
    font-size: 20px;
  }
  .mentor__card--desc {
    font-size: 14px;
    line-height: 18px;
  }
  .mentor-block {
    background-image: url("../assets/images/mentors-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
  }
}
@media (max-width: 576px) {
  .mentor--image {
    height: 44px;
  }
}

@media (min-width: 767px) {
  .mentor__container {
    margin-top: 35px;
  }

  .mentor-block {
    padding: 10px;
    width: 33.33%;
  }

  .mentor__card--image {
    width: 148px;
    height: 148px;
  }

  .mentor {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mentor__card-container {
    flex-direction: row;
  }

  .mentor__card--name {
    font-size: 22.92px;
  }

  .mentor__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }

  .mentor--image {
    width: auto;
    /* height: 40px; */
  }

  .meet-title-block {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 10px;
    padding-top: 30px;
  }

  .mentor__title--text {
    font-size: 50px;
  }
}

@media (min-width: 1023px) {
  .mentor {
    width: 100%;
    max-width: 1275px;
  }

  .mentor__card-container {
    gap: 18px;
  }
}
