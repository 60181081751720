.equation__container {
  position: relative;
  background-image: url("../assets/images/img3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  width: 100%;
}

.equation {
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 1)
  );
}

.equation__image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.equation__img-container {
  width: 100%;
  height: 200px;
  /* background-image: url("../assets/images/img3.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */

  position: relative;
}

.eqn-ttl {
  width: 100%;
  position: absolute;
  top: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.equation__title-block {
  /* position: absolute;
    top: 300px; */
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 0px;
}

.equation__text-why {
  color: #fff;
  font-size: 30px;
  margin: 0px;
  font-family: "Manrope", sans-serif;
}

.equation__text-my {
  font-family: "Manrope", sans-serif;
  color: #fcb61a;
  font-size: 36px;
  font-weight: 800;
  margin: 0px;
  padding: 0px;
}
.eqn-ttl .eqn--line {
  display: block;
  max-width: 430px;
}
.equation__text-eqn {
  font-family: "Manrope", sans-serif;
  color: #fff;
  font-size: 36px;
  font-weight: 800;
  margin: 0px;
  padding: 0px;
}

.eqn--line {
  /* position: absolute;
    top: 370px; */
  display: none;
  width: 30%;
}

.equation__content-block {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1275px;
  /* background-color: rgba(0, 0, 0, 0.8); */
  margin-top: 250px;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 1)); */
  /* background: radial-gradient(ellipse at center top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.6) 60%, rgba(0, 0, 0, 0.8) 80%, rgba(0, 0, 0, 1) 100%); */
}

.equation__card-block {
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 18px;
}

.equation__card {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
  justify-content: space-between;
  /* opacity: 0.8; */
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #121212;
  background-image: url("../assets/images/eqnbg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.equation__card--elps1 {
  position: absolute;
  width: 80px;
  height: 50px;
  right: 0px;
  bottom: 0px;
  background: #ff00d6;
  filter: blur(10px);
  z-index: -1;
}

.equation__card--elps2 {
  position: absolute;
  width: 100px;
  height: 50px;
  right: 30px;
  top: 0px;
  background: #1f00ff;
  filter: blur(15px);
  transform: rotate(-58.02deg);
  z-index: -1;
}

.equation__card--textb {
  font-family: "Manrope", sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
}

.equation__card--textr {
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  line-height: 20px;
  width: 76%;
  padding-top: 12px;
}

.eqn--line {
  display: none;
}
@media (max-width: 1279px) {
  .equation {
    padding: 0 20px;
  }
}
@media (max-width: 766px) {
  .equation__card-block {
    width: 100%;
  }
  .equation__content-block {
    padding-bottom: 40px;
    margin-top: 194px;
  }
  .equation__text-why {
    font-size: 31px;
  }
  .equation__text-my {
    font-weight: 46px;
  }
  .equation__text-eqn {
    font-size: 46px;
  }
  .equation__card--textr {
    font-size: 13px;
  }
  .eqn-ttl {
    padding-left: 0;
  }
  .equation__content-block {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .equation__container {
    position: relative;
    background-image: url("../assets/images/equation-mobile-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    width: 100%;
  }
  .equation__title-block {
    line-height: 35px;
  }
  .eqn-ttl .eqn--line {
    display: none;
  }
}

@media (max-width: 576px) {
  .equation__card img {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 767px) {
  .equation__container {
    margin-top: 20px;
  }

  .equation__img-container {
    display: flex;
    justify-content: center;
  }

  .equation__title-block {
    flex-direction: row;
    align-items: baseline;
    gap: 10px;
  }

  .equation__content-block {
    align-self: center;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    padding-bottom: 50px;
  }

  .equation__card {
    padding: 18px;
  }

  .equation__card--textb {
    font-size: 17.69px;
  }

  .equation__card--textr {
    font-size: 20px;
  }

  .eqn--line {
    display: block;
  }

  .equation__text-why {
    font-size: 50px;
  }

  .equation__text-my {
    font-size: 50px;
  }

  .equation__text-eqn {
    font-size: 50px;
  }

  .eqn-ttl {
    align-items: center;
  }
}

@media (min-width: 1023px) {
  .equation__card {
    padding: 20px 20px 25px;
  }

  .equation__card--textb {
    font-size: 27.69px;
  }

  .equation__card--textr {
    font-size: 20px;
  }

  .equation__card img {
    width: 82px;
    height: 82px;
  }

  .eqn-ttl {
    top: 380px;
  }

  .equation__content-block {
    margin-top: 300px;
  }
}
