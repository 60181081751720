.timeline__container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1275px;
  padding-top: 72px;
}

.timeline__title-text {
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 50px;
  font-weight: 800;
  margin: 0;
}

.timeline__accordions {
  width: 100%;
  max-width: 851px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: 20px;
}

.timeline--icon {
  width: 60px;
  height: 40px;
  display: none;
}

@media (max-width: 766px) {
  .timeline__title-text {
    font-size: 46px;
  }
}

@media (min-width: 767px) {
  .timeline__container {
    margin-top: 20px;
  }

  .timeline {
    width: 100%;
  }

  .timeline--icon {
    display: block;
  }

  .timeline--line {
    display: none;
  }
}

@media (min-width: 1023px) {
  .timeline {
    width: 100%;
  }

  .timeline--icon {
    width: 80px;
    height: 78px;
  }

  .timeline__title-text {
    font-size: 50px;
  }
}
