.navbar__container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 1050;
}

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.navbar__logo--image-white {
  width: 90px;
}

.navbar__logo--image {
  width: 100px;
  display: none;
  position: absolute;
  left: 0px;
  top: 10px;
}

.navbar__links {
  display: none;
}

.navbar__ham {
  margin-top: -10px;
}

.navbar__icon {
  font-size: 24px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  z-index: 10;
  padding: 10px;
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile--link {
  text-align: center;
  font-size: 15px;
  text-decoration: none;
  color: #000;
  font-family: "inter-ebold";
}

.close-icon {
  transform: rotate(90deg);
  font-size: 20px;
}

.web--link {
  font-family: "inter-bold";
  text-decoration: none;
  color: #000;
  font-size: 17.2px;
}

.web--link-btn {
  background-color: #ffe52e;
  background: linear-gradient(180deg, #ffe52e 21.22%, #c2aa00 85.36%);
  color: #000;
  border: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 7px 44px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 17.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100px; */
}

@media (min-width: 767px) {
  .navbar__container {
    justify-content: center;
  }

  .navbar__logo--image-white {
    display: none;
  }

  .navbar__logo--image {
    top: -8px;
  }

  .navbar__ham {
    display: none;
  }

  .navbar__container {
    background-color: #fff;
  }

  .navbar {
    width: 80%;
    /* padding: 10px; */
  }

  .navbar__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    margin-top: -5px;
  }

  .navbar__links .web--link {
    padding: 0 21px;
  }
  .navbar__logo--image {
    display: flex;
    /* margin-left: -400px; */
    width: 141px;
  }
}

@media (min-width: 1023px) {
  .navbar {
    width: 100%;
    max-width: 1275px;
  }
}

@media screen and (max-width: 1339px) {
  .equation__content-block {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
@media screen and (max-width: 766px) {
  .navbar__container {
    background: #000000b0;
  }
}
