.footer__container {
  background-color: #171717;
  padding: 20px;
}

.footer {
  max-width: 620px;
  margin: 0 auto;
}
.footer--image-block {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.footer__container p {
  font-family: "Manrope", sans-serif;
  font-size: 21.64px;
  font-weight: 400;
  color: #fff;
}
.footer__contact-card p {
  color: #9f9f9f;
}

.footer__logo-image {
  margin-bottom: 10px;
}

.footer__contact-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.footer__brand--container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer__brand-icon {
  font-size: 28px;
}

.foot--txt {
  margin-bottom: 20px;
}

.footer-Follow-us {
  justify-content: center;
  padding-top: 42px;
  margin-bottom: 30px !important;
}
.footer-Follow-us .footer-follow-text {
  display: block;
  width: 100%;
  font-family: "Manrope", sans-serif;
  font-size: 23.02px;
  font-weight: 400;
  color: #fff;
}
.footer-Follow-us .footer__brand--container p {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 28px !important;
}
.footer__container p.footer-copyright-text {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.5px;
}
.footer__container p.foot--txt {
  padding-top: 7px;
}
@media (max-width: 766px) {
  .footer__container {
    padding: 40px 20px;
    margin-top: 100px;
  }
  .footer--image-block {
    text-align: left;
  }
  .footer__container p {
    font-size: 16px;
    line-height: 21px;
  }
  .footer-Follow-us .footer-follow-text {
    font-size: 16px;
    line-height: 20px;
  }
  .footer-Follow-us .footer__brand--container p {
    font-size: 20px !important;
  }
  .footer__brand-icon {
    font-size: 20px;
  }
  .footer__contact-container .footer__contact-card p {
    font-size: 15px;
  }
}
@media (max-width: 414px) {
  .footer__contact-container .footer__contact-card p {
    font-size: 14px;
  }
}

@media (min-width: 767px) {
  .footer__container {
    margin-top: 162px;
  }
  .footer--middle {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1197px) {
  .footer__container {
    padding: 40px;
  }
}
