.mentees__container {
  padding: 30px;
}

.mentees__title-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.mentees__title--reg {
  font-family: "Manrope", sans-serif;
  text-align: center;
  font-size: 20px;
  color: #242421;
}

.mentees__title--bold {
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 30px;
  color: #242421;
}

.previous_carousel .react-multi-carousel-list {
  position: inherit;
}
.previous_carousel .react-multiple-carousel__arrow::before {
  font-size: 25px;
  color: #9c9c9c;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
  font-weight: 800;
}

.previous_carousel .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: transparent !important;
  min-width: inherit;
  min-height: inherit;
  opacity: 1;
  cursor: pointer;
}
.mentees__container {
  max-width: 1632px;
  margin: 0 auto;
  position: relative;
}
.previous_carousel .react-multiple-carousel__arrow--left {
  left: 0;
}
.previous_carousel .react-multiple-carousel__arrow--right {
  right: 0;
}

@media (max-width: 1653px) {
  .previous_carousel .react-multiple-carousel__arrow--left {
    left: 15px !important;
  }
  .previous_carousel .react-multiple-carousel__arrow--right {
    right: 18px !important;
  }
}

@media (max-width: 766px) {
  .mentees__container {
    padding: 20px 20px;
  }
  .mentees__title-card {
    line-height: 42px;
  }
  .mentees__title--reg {
    font-size: 36px;
  }
  .mentees__title--bold {
    font-size: 46px;
  }
}

@media (max-width: 414px) {
  .previous_carousel .react-multiple-carousel__arrow--left {
    left: 7px !important;
  }
  .previous_carousel .react-multiple-carousel__arrow--right {
    right: 10px !important;
  }
}

@media (min-width: 767px) {
  .mentees__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mentees {
    width: 100%;
    max-width: 1572px;
  }

  .mentees__title-card {
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    gap: 10px;
  }

  .mentees__title--reg {
    font-size: 30px;
  }

  .mentees__title--bold {
    font-size: 30px;
  }
}

@media (min-width: 1023px) {
  .mentees__title--reg {
    font-size: 50px;
  }

  .mentees__title--bold {
    font-size: 50px;
  }
}
