.faq__container {
  padding: 30px;
  /* background: #FE8616; */
  /* filter: blur(35px); */
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
  position: relative;
}

.faq__title--text {
  font-family: "Manrope", sans-serif;
  margin-bottom: 15px;
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  color: #242121;
}

.faq__question-container {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

@media (max-width: 766px) {
  .faq__container {
    padding: 10px 20px 30px;
  }
  .faq__title--text {
    font-size: 46px;
  }
}

@media (min-width: 767px) {
  .faq__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .faq {
    width: 80%;
  }

  .faq__title--text {
    font-size: 50px;
  }
}

@media (min-width: 1023px) {
  .faq {
    width: 70%;
  }

  .faq__title--text {
    font-size: 50px;
  }
}

@media (min-width: 1198px) {
  .faq {
    width: 60%;
  }
}
