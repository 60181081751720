/* @font-face {
  font-family: "inter-regular";
  src: local("Inter-Regular"),
    url("./assets/fonts/Inter-Regular.ttf") format("truetype");
} */
@font-face {
  font-family: "inter-regular";
  src: local("Manrope-Regular"),
    url("./assets/fonts/Manrope-Regular.ttf") format("truetype");
}

/* @font-face {
  font-family: "inter-semibold";
  src: local("Inter-SemiBold"),
    url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
} */
@font-face {
  font-family: "inter-semibold";
  src: local("Manrope-SemiBold"),
    url("./assets/fonts/Manrope-SemiBold.ttf") format("truetype");
}

/* @font-face {
  font-family: "inter-bold";
  src: local("Inter-Bold"),
    url("./assets/fonts/Inter-Bold.ttf") format("truetype");
} */
@font-face {
  font-family: "inter-bold";
  src: local("Manrope-Bold"),
    url("./assets/fonts/Manrope-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "inter-ebold";
  src: local("Manrope-ExtraBold"),
    url("./assets/fonts/Manrope-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "inter-medium";
  src: local("Manrope-Medium"),
    url("./assets/fonts/Manrope-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "inter-light";
  src: local("Manrope-Light"),
    url("./assets/fonts/Manrope-Light.ttf") format("truetype");
}

@font-face {
  font-family: "fugaz-regular";
  src: local("FugazOne-Regular"),
    url("./assets/fonts/FugazOne-Regular.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "inter-regular";
  font-size: 14px;
}

@media (max-width: 426px) {
  * {
    font-size: 13px;
  }
}

@media (max-width: 376px) {
  * {
    font-size: 12px;
  }
}