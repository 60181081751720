.enroll__container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enroll__title-textR {
  font-size: 28px;
  font-family: "Manrope", sans-serif;
  color: #242121;
  text-align: center;
  letter-spacing: -0.58px;
}

.perk-round-block {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
}

.enroll__title-text {
  text-align: center;
  font-family: "Manrope", sans-serif;
  font-size: 30px;
}

.enroll__title-textB {
  font-size: 30px;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  color: #242121;
  text-align: center;
  letter-spacing: -0.58px;
  position: relative;
}

.enroll-pc {
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  color: #242121;
  font-weight: 800;
}

.enroll-3 {
  font-size: 16px;
  margin-bottom: 20px;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #242121;
}

.enroll-e {
  width: 100%;
  max-width: 380px;
  font-family: "Manrope", sans-serif;
  color: #242121;
  font-size: 16px;
  font-weight: 400;
}

.enroll__emoji-textR {
  display: flex;
}

.perk-round-block {
  margin-bottom: 20px;
}

.perk-round {
  background-image: url("../assets/images/perkRound.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 3px;
  /* width: 100%; */
  padding: 0px 10px;
  font-family: "Manrope", sans-serif;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 90px;
}

.enroll__text-card {
  padding: 35px;
  border-radius: 5px;
  z-index: 1;
  /* background-color: #fff; */
  position: relative;
  overflow: hidden;
}
.enroll__text-card .bookmark-img {
  width: 43px;
}

.enroll__text--elps1 {
  position: absolute;
  width: 90px;
  height: 60px;
  right: 10px;
  bottom: 20px;

  background: #f9d411;
  filter: blur(70px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: -5;
}

.enroll__text--elps2 {
  position: absolute;
  width: 100px;
  height: 70px;
  right: -30px;
  bottom: 20px;

  background: #fe8616;
  border: 1px solid #000000;
  filter: blur(85px);
  transform: matrix(-1, 0, 0, 1, 0, s0);
  z-index: -5;
}

.enroll__emoji-card {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 5px;
}

.enroll__emoji-textB {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  color: #242121;
  font-weight: 800;
}

.enroll__emoji-textR {
  font-size: 15px;
  font-family: "Manrope", sans-serif;
  color: #242121;
  font-weight: 400;
}

.enroll__perks-block {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 10px;
}

.enroll__perks-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
  /* width: 70%; */
}

.enroll__perks-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.enroll__perks-item img {
  width: 23px;
}
.enroll__perks-item p {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  text-align: left;
}

.enroll__button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  padding-top: 80px;
  padding-bottom: 52px;
}
.enroll__button-container a {
  width: 100%;
  width: 100%;
  max-width: 260px;
}
.enroll__btn-enroll {
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  background-color: #ffe52e;
  color: #000;
  border: none;
  padding: 6px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 100%;
  height: 56px;
  cursor: pointer;
  background-image: url("../assets/images/lineWhite.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  /* background: linear-gradient(180deg, #FFE52E 21.22%, #C2AA00 85.36%); */
}

.enroll__btn-pay {
  font-family: "Manrope", sans-serif;
  background: transparent;
  font-weight: 600;
  color: #000;
  border: none;
  border: 2px solid #ffe52e;
  border-width: 2px;
  border-style: solid;
  /* border-image: linear-gradient(180deg, #FFE52E 21.22%, #C2AA00 85.36%) 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
  border-radius: 6px;
  width: 100%;
  height: 56px;
  cursor: pointer;
}

.web--link-btn {
  font-family: "inter-semibold";
}

.book--big {
  width: 90px;
  position: absolute;
  right: -7px;
  bottom: -15px;
  z-index: -1;
}

.quot {
  position: absolute;
  top: 0px;
}
.enroll__text-block {
  width: 47%;
  padding: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  background-color: #fff;
  background-image: url("../assets/images/enroll-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border: solid 2px #ebebeb;
  border-bottom: none;
  border-right: none;
}

@media (min-width: 767px) {
  .enroll__container {
    margin-top: 20px;
  }

  .enroll__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    padding-top: 30px;
  }

  .enroll__text-block {
    width: 45%;
  }

  .enroll__emoji-block {
    width: 45%;
  }

  .enroll__perks-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* gap: 20px; */
  }

  .enroll__perks-card {
    width: 45%;
  }

  .enroll__button-container {
    flex-direction: row;
    justify-content: center;
  }

  .enroll__perks-item p {
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    color: #242121;
    font-weight: 800;
  }

  .enroll-title-block {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 10px;
    padding-top: 67px;
    margin-bottom: 20px;
  }

  .enroll__btn-enroll {
    background: linear-gradient(180deg, #ffe52e 21.22%, #c2aa00 85.36%);
    font-size: 25px;
  }

  .enroll__btn-pay {
    font-size: 25px;
  }

  .enroll__top {
    margin-bottom: 50px;
  }

  .enroll__title-textR {
    font-size: 36px;
  }

  .enroll__title-textB {
    font-size: 36px;
  }

  .enroll__text--elps1 {
    width: 180px;
    height: 60px;
  }

  .enroll__text--elps2 {
    width: 180px;
    height: 120px;
  }

  .enroll__emoji-textB {
    font-size: 33.92px;
  }

  .enroll-pc {
    font-size: 33.92px;
  }

  .enroll-3 {
    font-size: 29.04px;
  }

  .enroll-e {
    font-size: 25.84px;
  }

  .enroll__emoji-textR {
    font-size: 29.04px;
  }

  .perk-round-block {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 10px;
    padding-top: 65px;
    margin-bottom: 40px;
  }

  .enroll__title-text {
    font-size: 34px;
    color: #242121;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    letter-spacing: -0.58px;
  }

  .perk-round {
    font-family: "Manrope", sans-serif;
    color: #242121;
    font-weight: 800;
    letter-spacing: -0.58px;
    font-size: 36px;
  }

  .enroll__title-text {
    font-size: 50px;
  }

  .enroll__perks-item p {
    font-size: 33px;
  }

  .enroll__emoji-card img {
    width: 98px;
  }

  .quot {
    display: none;
  }
}

@media (min-width: 1023px) {
  .enroll {
    width: 100%;
    max-width: 1275px;
  }

  .enroll__top {
    /* justify-content: center; */
    gap: 10px;
  }

  .enroll__emoji-block {
    width: 47%;
    margin-top: -5px;
  }

  .enroll__perks-block {
    padding: 22px;
  }

  .enroll__title-textR {
    font-size: 50px;
  }

  .enroll__title-textB {
    font-size: 50px;
  }

  .enroll-pc {
    font-size: 33.92px;
  }

  .enroll-3 {
    font-size: 29.04px;
  }

  .enroll-e {
    font-size: 25.84px;
  }

  .perk-round {
    font-size: 50px;
  }

  .enroll__title-text {
    font-size: 50px;
  }
}

@media (max-width: 1239px) {
  .enroll__perks-item p {
    font-size: 25px;
  }
}

@media (max-width: 1139px) {
  .enroll__perks-item p {
    text-align: left;
    font-size: 22px;
  }
}
@media (max-width: 1023px) {
  .enroll-pc {
    font-size: 20px;
  }
  .enroll-3 {
    font-size: 18px;
  }
  .enroll-e {
    font-size: 15px;
  }
  .enroll__emoji-block {
    width: 50%;
  }
  .enroll__emoji-textB {
    font-size: 22px;
  }
  .enroll__emoji-textR {
    font-size: 18px;
  }
  .enroll__perks-item p {
    font-size: 18px;
  }
}
@media (max-width: 766px) {
  .enroll__container .enroll {
    width: 100%;
    padding: 0px;
  }
  .enroll__title-textR {
    font-size: 31px;
  }
  .enroll__title-textB {
    font-size: 46px;
  }
  .enroll__text-card {
    padding: 18px;
  }
  .enroll-pc {
    font-size: 21px;
  }
  .enroll-3 {
    font-size: 17px;
  }
  .enroll-e {
    font-size: 16px;
  }
  .enroll__emoji-block {
    max-width: 299px;
    width: 100%;
    margin: 0 auto;
  }
  .enroll__title-text {
    font-size: 31px;
  }
  .perk-round {
    font-size: 46px;
    font-weight: 800;
  }
  .enroll__perks-card {
    margin-left: 0;
  }
  .enroll__perks-item p {
    font-size: 21px;
    font-weight: 600;
    text-align: left;
  }
  .enroll__button-container {
    padding: 30px 0 20px;
  }
  .enroll__button-container a {
    width: 216px;
  }
  .enroll__btn-enroll {
    font-size: 20px;
    font-weight: 600;
    width: 216px;
    height: 46px;
  }
  .enroll__btn-pay {
    font-size: 20px;
    font-weight: 600;
    width: 216px;
    height: 46px;
  }
  .enroll__container {
    padding: 12px 20px 20px;
  }
  .enroll-title-block {
    padding-bottom: 32px;
  }
  .enroll__text-card .bookmark-img {
    width: 28px;
  }
  .book--big {
    width: 60px;
    right: 0;
    bottom: -5px;
  }
  .perk-round-block {
    margin-bottom: 24px;
    margin-top: 12px;
  }
  .enroll__text-block {
    width: 100%;
  }
}

@media (max-width: 413px) {
  .enroll__perks-item img {
    width: 16px;
  }
  .enroll__perks-item p {
    font-size: 18px;
  }
}
