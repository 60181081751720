.stories__container {
  padding: 30px;
}

.stories__title-card {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
  margin-bottom: 15px;
}

.stories__text--reg {
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  color: #242121;
}

.stories__text--bold {
  font-family: "inter-ebold";
  font-size: 30px;
  color: #242121;
}

.slider__item {
  /* align-items: center; */
  /* background-color: red; */
  padding: 10px;
}
.stories__container .slider__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 588px;
}
.stories__container .slider__item img {
  object-fit: inherit !important;
  height: auto !important;
}
.stories__container .react-multi-carousel-list {
  position: inherit;
}
.stories__container .react-multiple-carousel__arrow::before {
  font-size: 25px;
  color: #9c9c9c;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
  font-weight: 800;
}

.stories__container .react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: transparent !important;
  min-width: inherit;
  min-height: inherit;
  opacity: 1;
  cursor: pointer;
}
/* .stories__container .react-multiple-carousel__arrow--left {
  left: 7%;
}
.stories__container .react-multiple-carousel__arrow--right {
  right: 7%;
} */

.stories__container {
  max-width: 1632px;
  margin: 0 auto;
  position: relative;
}
.stories__container .react-multiple-carousel__arrow--left {
  left: 0;
}
.stories__container .react-multiple-carousel__arrow--right {
  right: 0;
}
@media (max-width: 1653px) {
  .stories__container .react-multiple-carousel__arrow--left {
    left: 15px !important;
  }
  .stories__container .react-multiple-carousel__arrow--right {
    right: 18px !important;
  }
}
@media (max-width: 766px) {
  .stories__container {
    padding: 20px;
  }
  .stories__text--reg {
    font-size: 36px;
  }
  .stories__text--bold {
    font-size: 46px;
  }
  .stories__container .slider__item img {
    height: 100% !important;
    object-fit: contain !important;
  }
}
@media (max-width: 414px) {
  .mentees .previous_carousel .react-multiple-carousel__arrow--left {
    left: 7px !important;
  }
  .mentees .previous_carousel .react-multiple-carousel__arrow--right {
    right: 10px !important;
  }
}
@media (min-width: 767px) {
  .stories__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .stories {
    width: 100%;
    max-width: 1572px;
  }

  .stories__text--reg {
    font-size: 30px;
  }

  .stories__text--bold {
    font-size: 30px;
  }
}

@media (min-width: 1023px) {
  .stories__text--reg {
    font-size: 50px;
  }

  .stories__text--bold {
    font-size: 50px;
  }
}
